import React, { Component } from "react";
import LogoGoogle from "../images/logo/logo_google.png";
import LogoTAF from "../images/logo/logo_taf.png";

interface Props {
  svgIcon?: string;
  description?: string;
}

export default class InformationContainer extends Component<Props> {
  render() {
    return (
      <div className="bg-blue-200 bg-opacity-25">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl text-center font-extrabold text-blue-600 sm:text-4xl">
            DMAP is supported by
          </h2>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:max-w-2xl md:mx-auto lg:mt-8">
            <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
              <img
                className="max-h-24"
                src={LogoTAF}
                alt="The Asia Foundation"
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
              <img className="max-h-24" src={LogoGoogle} alt="Google" />
            </div>
          </div>
          <p className="text-center font-extrabold mt-8 text-xl text-blue-600">
            DMAP was launched during the APEC CEO Summit 2021
          </p>
          <p className="text-center mt-2 text-lg text-gray-500">
            Contact Us : <u><a target="_blank" rel="noreferrer" href="mailto:info@dmap-community.org">info@dmap-community.org</a></u>
          </p>
        </div>
      </div>
    );
  }
}
