import {
  AcademicCapIcon,
  GlobeAltIcon,
  UserAddIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import React, { Component } from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import LandingVideo from "../components/LandingVideo";
import Members from "../components/Members";
import Mission from "../components/Mission";
import Projects from "../components/Projects";
import SEO from "../components/Seo";
import SupportedLogos from "../components/SupportedLogos";

const missionFeatures: Object[] = [
  {
    name: "Providing Mentorship",
    description:
      "Growing the next generation of digital makers by providing online and in-person mentorship programs in local languages to those in Asia-Pacific economies",
    icon: UserGroupIcon,
  },
  {
    name: "Expanding Network",
    description:
      "Connecting digitally-focused individuals locally (in their own region) and internationally (in other Asia-Pacific regions) through in-person events and digital meetups",
    icon: GlobeAltIcon,
  },
  {
    name: "Empowering Communities",
    description:
      "Empowering students and economies using open source projects and make a positive impact in APEC communities",
    icon: UserAddIcon,
  },
  {
    name: "Developing New Skills",
    description:
      "Equipping individuals with the necessary skills to help them to tackle issues in their local community and prepare them for future opportunities",
    icon: AcademicCapIcon,
  },
];

export default class Home extends Component {
  render() {
    return (
      <div className="relative bg-white overflow-hidden">
        <SEO
          title="DMAP - Home"
          description="DMAP (Digital Makers Asia Pacific is a team of Software Engineers and Developers that got together by participating in one or more of the APEC App Challenge events. We are aiming to build a community of professionals making an impact in the world!"
        />
        <Hero
          title="Digital Makers"
          titleBlue="Asia Pacific"
          description="DMAP is a community of Software Engineers, Designers, and Developers including APEC App Challenge alumni who want to empower digital innovation across the Asia-Pacific region."
          addDescription="Through policy, community outreach, mentorship and education, we focus on long-term solutions that bridge communities with policy makers."
          link="/about"
          linkTitle="Learn More About Us"
        />
        <Mission
          title="Vision of DMAP"
          description="We strive to create initiatives and tools that expand digital skills across the Asia-Pacific region. DMAP also aims to serve as a bridge between communities and policymakers, to promote a local-based focus on digitalization, expand the benefits of digital tools and skills, and drive inclusive growth."
          features={missionFeatures}
        />
        <Projects />
        <Members limit={6} />
        <LandingVideo />
        <SupportedLogos />
        <Footer />
      </div>
    );
  }
}
