import React, { Component } from "react";
import HomeVideo from "../images/home_video.webp";
import Button from "./base/Button";

export default class LandingVideo extends Component {
  handleNavigateVideo = () => {
    window.open("https://youtu.be/FnilWtDWjbg");
  };

  render() {
    return (
      <div className="my-16 mx-auto max-w-7xl px-4 sm:my-24 sm:px-6 lg:my-32">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="my-auto sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h3>
              <span className="mt-1 block text-3xl tracking-tight font-extrabold sm:text-4xl">
                <span className="block text-blue-600">
                  DMAP - Launching Video
                </span>
              </span>
            </h3>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              DMAP was launched during the APEC CEO Summit 2021, a virtual
              gathering of the world’s most powerful CEOs, business leaders,
              entrepreneurs, thought leaders, prime ministers and presidents of
              APEC economies.
            </p>
            <Button
              className="max-w-xs mt-7"
              title="Learn More"
              link=""
              onClick={this.handleNavigateVideo}
            />
          </div>
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
              <button
                type="button"
                className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={this.handleNavigateVideo}
              >
                <img className="w-full" src={HomeVideo} alt="" />
                <div
                  className="absolute inset-0 w-full h-full flex items-center justify-center"
                  aria-hidden="true"
                >
                  <svg
                    className="h-20 w-20 text-blue-600"
                    fill="currentColor"
                    viewBox="0 0 84 84"
                  >
                    <circle opacity="0.9" cx={42} cy={42} r={42} fill="white" />
                    <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
