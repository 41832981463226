import {
  CogIcon,
  DesktopComputerIcon,
  HeartIcon,
} from "@heroicons/react/outline";
import React, { Component } from "react";
import HomeAsia from "../images/home_projects.webp";

const transferFeatures = [
  {
    id: 1,
    name: "APEC App Challenge",
    description:
      "In collaboration with APEC, we organize the annual APEC App Challenge which inspires developers to tackle real world problems. The App Challenge has been previously conducted in Vietnam (2017), Papua New Guinea (2018), Chile (2019), Malaysia (2020), New Zealand (2021), and recently Thailand (2022).",
    icon: CogIcon,
  },
  {
    id: 2,
    name: "MySukarela",
    description:
      "MySukarela, a collaboration between the Malaysia Red Crescent Society and The Asia Foundation, serves as a platform to connect volunteers with nearby non-profit organizations (NGO) conducting humanitarian work.",
    icon: HeartIcon,
  },
  {
    id: 3,
    name: "Let's Code Asia",
    description:
      "A platform for empowering kids with coding skills in their local language. Our goal is to empower kids and mentoring them to prepare them for their brighter future. At the moment most of the kids’ coding resources are in English, and there is no strong presence of mentors in less-privileged economies. That’s why we decided to change that, translating coding resources for kids in their own language, and mentoring them locally.",
    icon: DesktopComputerIcon,
  },
];

export default class Projects extends Component {
  render() {
    return (
      <div className="py-16 bg-white overflow-hidden lg:py-24">
        <div className="relative max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative mt-6 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                Projects That We Are Involved In
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                We are actively contributing to multiple humanitarian projects.
                Open sourcing and mentoring each other are always the core
                philosophy of the DMAP community. Feel free to reach us out if
                there are any questions or requests!
              </p>

              <dl className="mt-10 space-y-10">
                {transferFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <img
                className="relative mx-auto rounded-md shadow-md object-cover"
                width={490}
                src={HomeAsia}
                alt=""
              />
            </div>
          </div>

          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
      </div>
    );
  }
}
